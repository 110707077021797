$(window).on('scroll',
  ()->
    scroll = $(window).scrollTop()
    wHeight = $(window).height()
    headerVal = $('#l-header').outerHeight(true)

    $('.p-box').each(
      ()->
        oTop = $(this).offset().top
        if $(this).find('.p-box__headline').hasClass('is-active')
        else
          if scroll > oTop - headerVal - 500
            $(this).find('.p-box__headline').addClass('is-active')
    )

    if scroll > $('#plan .p-plan__list').offset().top - headerVal - 500
      $('#plan .p-plan__list').find($('.p-plan__item')).each(
        (i,e)->
          setTimeout(
            ()->
              $(e).addClass('is-active')
            , (i * 150))
      )
      $('#plan .p-plan__text').addClass('is-active')

    if scroll > $('#feature').offset().top - headerVal - 200
      setTimeout(
        ()->
          $('.p-feature__pic').addClass('is-active')
        , 300)
)

ScrollReveal().reveal('.p-mainvisual__pic', {
  delay: 500,
  duration: 2400,
  opacity: 0,
  reset: false
})

ScrollReveal().reveal('.p-realization .p-box__lead', {
  delay: 1600,
  duration: 1800,
  origin: 'bottom',
  distance: '50px',
  reset: false
})

ScrollReveal().reveal('.p-realization .p-realization__box', {
  delay: 1700,
  duration: 1600,
  origin: 'bottom',
  distance: '50px',
  reset: false
})

ScrollReveal().reveal('.p-reasons__list .item1 .p-reasons__item-pic', {
  delay: 600,
  duration: 2000,
  origin: 'left',
  distance: '500px',
  opacity: 0,
  reset: false
})

ScrollReveal().reveal('.p-reasons__list .item2 .p-reasons__item-pic', {
  delay: 650,
  duration: 2000,
  origin: 'right',
  distance: '500px',
  opacity: 0,
  reset: false
})

ScrollReveal().reveal('.p-reasons__list .item3 .p-reasons__item-pic', {
  delay: 700,
  duration: 2000,
  origin: 'left',
  distance: '500px',
  opacity: 0,
  reset: false
})

ScrollReveal().reveal('.p-reasons__list .item4 .p-reasons__item-pic', {
  delay: 750,
  duration: 2000,
  origin: 'right',
  distance: '500px',
  opacity: 0,
  reset: false
})

ScrollReveal().reveal('.p-reasons__list .item5 .p-reasons__item-pic', {
  delay: 800,
  duration: 2000,
  origin: 'left',
  distance: '500px',
  opacity: 0,
  reset: false
})

ScrollReveal().reveal('.p-reasons__function .item1', {
  delay: 600,
  duration: 1600,
  origin: 'bottom',
  distance: '50px',
  reset: false
})

ScrollReveal().reveal('.p-reasons__function .item2', {
  delay: 650,
  duration: 1600,
  origin: 'bottom',
  distance: '50px',
  reset: false
})

ScrollReveal().reveal('.p-reasons__function .item3', {
  delay: 700,
  duration: 1600,
  origin: 'bottom',
  distance: '50px',
  reset: false
})

ScrollReveal().reveal('.p-reasons__function .item4', {
  delay: 750,
  duration: 1600,
  origin: 'bottom',
  distance: '50px',
  reset: false
})

ScrollReveal().reveal('.p-reasons__function .item5', {
  delay: 800,
  duration: 1600,
  origin: 'bottom',
  distance: '50px',
  reset: false
})

ScrollReveal().reveal('.p-reasons__function .item6', {
  delay: 850,
  duration: 1600,
  origin: 'bottom',
  distance: '50px',
  reset: false
})

ScrollReveal().reveal('.p-reasons__function .item7', {
  delay: 900,
  duration: 1600,
  origin: 'bottom',
  distance: '50px',
  reset: false
})

ScrollReveal().reveal('.p-reasons__function .item8', {
  delay: 950,
  duration: 1600,
  origin: 'bottom',
  distance: '50px',
  reset: false
})

ScrollReveal().reveal('.p-plan .p-plan__inner', {
  delay: 800,
  duration: 1600,
  origin: 'bottom',
  distance: '50px',
  reset: false
})

ScrollReveal().reveal('.p-flow .p-flow__list', {
  delay: 800,
  duration: 1600,
  origin: 'bottom',
  distance: '50px',
  reset: false
})

ScrollReveal().reveal('.p-faq .p-faq__list', {
  delay: 800,
  duration: 1600,
  origin: 'bottom',
  distance: '50px',
  reset: false
})
